import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  operationType: undefined,
  userSetting: undefined,
  guestSetting: undefined
}

export const settingSlice = createSlice({
  name: 'setting',
  initialState: { ...initialState },
  reducers: {
    sagaSuccess: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    sagaFailed: (state, action) => {
      state.operationType = undefined;
      state.isLoading = false
    },
    addClassroom: (state, action) => {
      state.isLoading = true
    },
    deleteClassroom: (state, action) => {
      state.isLoading = true
    },
    addSettings: (state, action) => {
      state.isLoading = true
    },
    deleteSettings: (state, action) => {
      state.isLoading = true
    },
    confirmSettings: (state, action) => {
      state.isLoading = true
    },
    getUserSettings: (state, action) => {
      state.isLoading = true
    },
    getGuestSettings: (state, action) => {
      state.isLoading = true
    }
  }
})

export const {
  sagaSuccess,
  addClassroom,
  deleteClassroom,
  addSettings,
  deleteSettings,
  confirmSettings,
  getUserSettings,
  getGuestSettings
} = settingSlice.actions

export default settingSlice.reducer