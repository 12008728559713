import { eventChannel } from "redux-saga";


function getEntitiesByChannel(firebase, node, entityId?, orderByChild?, entityKey?) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var myRef = rsf.ref(branchPath + node);
    if (orderByChild) {
        myRef = rsf.ref(branchPath + node).orderByChild(entityKey).equalTo(entityId);
    }
    return eventChannel((emitter) => {
        myRef.on("value", (snap) => {

            // if (orderByChild) {
            //     if (snap.val()) {
            //         emitter(snap.val());
            //     }
            // } else {
            if (typeof snap.val() === "number") {
                emitter(snap.val())
            } else {
                let list = [];
                snap.forEach((item) => {
                    let value = item.val()
                    if (value && !value.deleted) {
                        list.push(item.val());
                    }
                });
                emitter(list);
            }
            // }
        });
        return () => {
            console.log("fetch channel closed on node -", node)
            myRef.off();
        };
    });
}

function getEntityById(firebase, node, entityId?, orderByChild?) {
    const rsf = firebase.secondaryDb;
    let branchPath = firebase.sbp;
    var myRef = rsf.ref(branchPath + node);
    if (orderByChild) {
        myRef = rsf.ref(branchPath + node).child(entityId);
    }
    return new Promise((resolve, reject) => {
        myRef.once("value")
            .then((snap) => {
                if (orderByChild) {
                    resolve(snap.val());
                } else {
                    let list = [];
                    snap.forEach((item) => {
                        let value = item.val()
                        if (value && !value.deleted) {
                            list.push(item.val());
                        }
                    });
                    resolve(list);
                }
            })
            .catch((error) => {
                console.error("Error fetching data from Firebase:", error);
                reject(error);
            });
    });
}


export default {
    getEntityById,
    getEntitiesByChannel
}