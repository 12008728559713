import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lastUpdateNotification: undefined,
  updateNotificationChan: undefined,
  notifications: [],
  isLoading: false,
  operationType: undefined
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: { ...initialState },
  reducers: {
    sagaSuccess: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    sagaFailed: (state, action) => {
      state.operationType = undefined;
      state.isLoading = false;
    },
    getLastUpdateNotification: (state, action) => { },
    getNotifications: (state, action) => {
      state.isLoading = true;
    },
    markReadNotification: (state, action) => { },
    markReadAllNotifications: (state, action) => {
      state.isLoading = true
    }
  }
})

export const {
  sagaSuccess,
  getLastUpdateNotification,
  getNotifications,
  markReadNotification,
  markReadAllNotifications
} = notificationSlice.actions

export default notificationSlice.reducer