import "firebase/compat/storage";
import { call, takeLatest, put } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import Helper from "../../util/helper";
function* getCalendar(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingApi/calendar?startDate=${startDate}&endDate=${endDate}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.CALENDAR_SAGA_SUCCESS,
        payload: { isLoading: false, calendars: response.body.data }
      });
    }
    else {
      yield put({ type: actions.CALENDAR_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.CALENDAR_SAGA_FAILED });
    console.log("failed to fetch invoice list", err);
  }
}

function* getScheduleDetail(request) {
  try {
    const { firebase, packageId, date } = request.payload;
    let endpoint = `bookingApi/calendar/scheduleDetail?packageId=${packageId}&date=${date}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.CALENDAR_SAGA_SUCCESS,
        payload: { isLoading: false, scheduleDetail: response.body.data }
      });
    }
    else {
      yield put({ type: actions.CALENDAR_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.CALENDAR_SAGA_FAILED });
    console.log("failed to fetch invoice list", err);
  }
}

function* getSchedules(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingApi/calendar/schedules?startDate=${startDate}&endDate=${endDate}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.CALENDAR_SAGA_SUCCESS,
        payload: { isLoading: false, schedules: response.body.data }
      });
    }
    else {
      yield put({ type: actions.CALENDAR_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.CALENDAR_SAGA_FAILED });
    console.log("failed to fetch invoice list", err);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_CALENDAR, getCalendar);
  yield takeLatest(actions.GET_SCHEDULES, getSchedules);
  yield takeLatest(actions.GET_SCHEDULE_DETAIL, getScheduleDetail);
}