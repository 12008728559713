"use client";
const actions = {
    SIGN_IN: "login/signIn",
    INIT_LOGIN: "login/initLogin",
    SIGN_SUCCESS: "login/loginSuccess",
    SIGN_OUT_SUCCESS: "login/logoutSuccess",
    SIGN_OUT: "login/logout",
    UPDATE_CHANNEL: "login/updateChannel",
    CLOSE_CHANNELS: "login/closeChannels",
    UPDATE_FIREBASE_OBJ: "login/updateFirebaseObj",
    VALIDATE_USER_LOGIN: "login/validateUserLogin",
    VERIFY_PHONE_NUMBER: "login/verifyPhoneNumber",
    VERIFY_PHONE_SUCCESS: "login/verifyPhoneSuccess",
    LOGIN_WITH_PHONE: "login/loginWithPhone",
    LOGIN_WITH_PHONE_SUCCESS: "login/loginWithPhoneSuccess",
    SIGN_IN_WITH_GOOGLE: "login/signInWithGoogle",
    SIGN_UP_ACCOUNT: "login/signUpAccount",
    SIGN_UP_SUCCESS: "login/signUpSuccess",
    ATTACHHMENT_UPLOAD_PROGRESS: "login/attachmentUploadProgress",
    SLICE_OPERATION: "login/sagaSuccess",
    PARENT_SIGN_SUCCESS: "login/parentSignSuccess",
    NEW_USER_SUCCESS: "login/setNewUsers",
    CONTINUE_WITH_NEW_USER: "login/continueWithNewUser",
    LOGIN_SAGA_SUCCESS: "login/sliceOperation",
    FETCH_NEW_USERS: "login/fetchNewUsers",
    AUTH_SUCCESS: "login/authenticationSucess",

    // package actions
    SAVE_DISCOUNT: "package/saveDiscount",
    SAGA_SUCCESS: "package/sagaSuccess",
    SAGA_FAILED: "package/sagaFailed",
    GET_ALL_DISCOUNT: "package/getAllDiscount",
    DELETE_DISCOUNT: "package/deleteDiscount",
    CREATE_PACKAGE: "package/createPackage",
    FETCH_PACKAGES: "package/fetchPackage",
    DELETE_PACKAGE: "package/deletePackage",
    GET_PACKAGE_BY_ID: "package/getPackageById",
    GET_PKG_TYPE: "package/getPackageType",
    GET_AGE_RANGE: "package/getAgeRange",
    GET_SKILLS: "package/getSkills",
    GET_ALL_BOOKING: "package/getAllBooking",
    CHANGE_BOOKING_STATUS: "package/changeBookingStatus",
    GET_LISTING: "package/getListing",
    GET_LISTING_RAW_DATA: "package/getListingRawData",
    ACTIVE_AND_INACTIVE_PACKAGE: "package/activeInactivePackage",
    GET_ALL_CLASSROOMS: "package/getAllClassrooms",
    PACKAGE_UPDATE_GUEST: "package/updateGuest",




    SAVE_TAX: "package/saveTax",
    DELETE_TAX: "package/deleteTax",
    GET_ALL_TAX: "package/getAllTax",
    GET_ALL_PRICE_PACK: "package/getAllPricePack",
    SAVE_PRICE_PACK: "package/savePricePack",
    DELETE_PRICING_PACK: "package/deletePricingPack",
    GET_ALL_REGISTRATION_FEE: "package/getAllRegistrationFee",
    SAVE_REGISTRATION_FEE: "package/saveRegistrationFee",
    DELETE_REGISTRATION_FEE: "package/deleteRegistrationFee",
    GET_ALL_ADDITIONAL_CHARGE: "package/getAllAdditionalCharge",
    SAVE_ADDITIONAL_CHARGE: "package/saveAdditionalCharge",
    DELETE_ADDITIONAL_CHARGE: "package/deleteAdditionalCharge",
    GET_ALL_PRICE_PACK_BY_CHANNEL: "package/getAllPricePackByChannel",
    FETCH_PACKAGE_BOOKINGS: "package/fetchPackageBookings",
    FETCH_PRICING_PACKS_FOR_ALL_PACKAGES: "package/fetchPricingPacksForAllPackages",
    FETCH_PRICING_PACKS_BY_PACKAGE: "package/fetchPricingPackByPackage",
    FETCH_UPCOMING_CLASSES: "package/fetchUpcomingClasses",
    FETCH_PACKAGE_ACTIVITIES: "package/fetchPackageActivites",
    FETCH_PACKAGE_SCHEDULE: "package/fetchPackageSchedule",
    GET_CLASS_PACK_BY_PACKAGE_ID: "package/getClassPackByPackageId",
    EDIT_PACKAGE_DETAILS: "package/editPackageDetails",
    GET_PRICE_PACK_DATA_FOR_EDIT: "package/getPricePackDataForEdit",
    GET_CLASSLEVEL: "package/getClassLevel",
    GET_LANGUAGE: "package/getLanguage",
    GET_ALL_PACKAGE_NAMES: "package/getAllPackageNames",





    // activity actions
    ACTIVITY_CREATE_ACTIVITY: "bookingActivity/createBookingActivity",
    CREATE_BOOKING_ACTIVITY_SUCCESS: "bookingActivity/createBookingActivitySuccess",
    REQUEST_FAILED: "bookingActivity/requestFailed",
    FETCH_ACTIVITY: "bookingActivity/fetchActivity",
    ACTIVITY_REQUEST_SUCCESS: "bookingActivity/requestSuccess",
    GET_ACTIVITY_BY_ID: "bookingActivity/getActivityById",
    DELETE_ACTIVITY: "bookingActivity/deleteActivity",
    FETCH_FACILITIES: "bookingActivity/fetchFacilities",
    GET_ALL_ACTIVITY_NAMES: "bookingActivity/getAllActivityNames",












    // student actions
    UPDATE_STUDENT: "students/updateStudent",
    STUDENT_REQ_SUCCESS: "students/requestSuccess",
    STUDENT_REQUEST_FAILED: "students/requestFailed",
    ADD_PARENT: "students/addParent",
    FETCH_PARENTS: "students/fetchParents",
    DELETE_PARENT: "students/deleteParent",
    FETCH_STUDENT_PACKAGES: "students/fetchStudentPackages",
    CONFIRM_BOOKING: "students/confirmBooking",
    CANCEL_BOOKING: "students/cancelBooking",
    FETCH_STUDENT_SCHEDULES: "students/fetchStudentSchedules",
    GET_ALL_STUDENT: "students/getAllStudent",
    GET_ALL_STUDENT_PACKAGES: "students/getAllStudentPackages",
    // STUDENT_SAGA_FAILED: "students/studentSagaFailed",
    // STUDENT_SAGA_SUCCESS: "students/studentSagaSuccess",
    STUDENT_RESET_OPERATION_TYPE: "students/studdentResetOperationType",
    DELETE_STUDENT: "students/deleteStudent",
    GET_STUENT_DETAIL_BY_ID: "students/getStudentDetailById",
    SEND_INVITE_TO_PARENTS: "students/sendInviteToParent",
    ADD_SIBLING: "students/addSibling",

    //staff actions
    UPDATE_STAFF: "staffs/updateStaff",
    STAFF_REQ_SUCCESS: "staffs/requestSuccess",
    STAFF_REQUEST_FAILED: "staffs/requestFailed",
    GET_ALL_STAFF: "staffs/getAllStaff",
    FETCH_STAFF_SCHEDULES: "staffs/fetchStaffSchedules",
    // STAFF_SAGA_FAILED: "staffs/satffSagaFailed",
    // STAFF_SAGA_SUCCESS: "staffs/staffSagaSuccess",
    STAFF_PACKAGES_AND_ACTIVITIES: "staffs/getAllStaffPackagesAndActivities",
    DELETE_STAFF: "staffs/deleteStaff",
    GET_ALL_STAFF_PACKAGES: "staffs/getAllPackages",
    SEND_INVITE_TO_STAFFS: "staffs/sendInviteToTeachers",
    SET_ACTIVE_INACTIVE_STAFF: "staffs/setActiveInactiveStaff",


    //invoice actions
    INVOICE_SAGA_SUCCESS: "invoice/sagaSuccess",
    INVOICE_SAGA_FAILED: "invoice/sagaFailed",
    GET_INVOICE_LIST: "invoice/getInvoiceList",
    GET_STUDENT_INVOICE_LIST: "invoice/getStudentInvoiceList",
    GET_STUDENT_INVOICE_LIST_BY_STUDENT_ID: "invoice/getStudentInvoiceListFromStudentId",
    GET_TRANSACTION_LIST: "invoice/getTransactions",
    GET_ALL_TAXES: "invoice/getAllTaxes",
    GET_ALL_DISCOUNTS: "invoice/getAllDiscounts",
    GET_ALL_PACKAGES: "invoice/getAllPackages",
    GET_PRICE_PACK_BY_PACKAGE_ID: "invoice/getPricePackByPackageId",
    CREATE_INVOICE: "invoice/createInvoice",
    EDIT_INVOICE: "invoice/editInvoice",
    CREATE_BOOKING: "invoice/createBooking",
    RESET_OPERATION_TYPE: "invoice/resetOperationType",
    GET_INVOICE_BY_ID: "invoice/getInvoiceById",
    ADD_REFUND: "invoice/addRefund",
    ADD_PAYMENT: "invoice/addPayment",
    SEND_FEE_REMINDER: "invoice/sendFeeReminder",
    INVOICE_PDF: "invoice/invoicePDF",

    //calendar actions
    GET_CALENDAR: "calendar/getCalendar",
    GET_SCHEDULES: "calendar/getSchedules",
    GET_SCHEDULE_DETAIL: "calendar/getScheduleDetail",
    CALENDAR_SAGA_SUCCESS: "calendar/sagaSuccess",
    CALENDAR_SAGA_FAILED: "calendar/sagaFailed",

    //parent actions
    GET_PARENT_PRICE_PACK_BY_PACKAGE_ID: "parent/getPricePackByPackageId",
    FETCH_PARENT_UPCOMING_CLASSES: "parent/fetchUpcomingClasses",
    FETCH_PARENT_PACKAGE_ACTIVITIES: "parent/fetchPackageActivites",
    GET_PARENT_PACKAGE_BY_ID: "parent/getPackageById",
    PARENT_SAGA_SUCCESS: "parent/sagaSuccess",
    PARENT_SAGA_FAILED: "parent/sagaFailed",
    REQUEST_APPROVAL: "parent/requestApproval",
    PARENT_GET_ALL_PACKAGES: "parent/getAllPackages",
    PARENT_FETCH_PRICING_PACKS_FOR_ALL_PACKAGES: "parent/fetchPricingPacksForAllPackages",
    GET_SELLING_FAST: "parent/getSellingFast",
    UPDATE_WISHLIST: "parent/updateWishlist",
    GET_MY_BOOKINGS: "parent/getMyBookings",
    GET_NOTIFICATION_SETTINGS: "parent/getNotificationSettings",
    CHANGE_NOTIFICATION_SETTINGS: "parent/changeNotificationSettings",
    PARENT_LEAVE_REVIEW: "parent/leaveReview",


    // notification
    GET_LAST_UPDATE_NOTIFICATION: "notification/getLastUpdateNotification",
    GET_NOTIFICATIONS: "notification/getNotifications",
    MARK_READ_NOTIFICATION: "notification/markReadNotification",
    MARK_READ_ALL_NOTIFICATIONS: "notification/markReadAllNotifications",
    NOTIFICATION_SAGA_SUCCESS: "notification/sagaSuccess",
    NOTIFICATION_SAGA_FAILED: "notification/sagaFailed",

    //settings
    SETTING_SAGA_SUCCESS: "setting/sagaSuccess",
    SETTING_SAGA_FAILED: "setting/sagaFailed",
    SETTING_ADD_CLASSROOM: "setting/addClassroom",
    SETTING_DELETE_CLASSROOM: "setting/deleteClassroom",
    SETTING_ADD_SETTINGS: "setting/addSettings",
    SETTING_DELETE_SETTINGS: "setting/deleteSettings",
    SETTING_CONFIRM_SETTINGS: "setting/confirmSettings",
    SETTING_GET_USER_SETTINGS: "setting/getUserSettings",
    SETTING_GET_GUEST_SETTINGS: "setting/getGuestSettings",

    //dashboard
    DASHBOARD_SAGA_SUCCESS: "dashboard/sagaSuccess",
    DASHBOARD_SAGA_FAILED: "dashboard/sagaFailed",
    GET_DASH_BOOKINGS: "dashboard/getBookings",
    GET_DASH_REVENUE: "dashboard/getRevenue",
    GET_DASH_STUDENT_STAFF: "dashboard/getStudentStaff",
    GET_DASH_PACKAGES: "dashboard/getPackages",
    GET_DASH_UPCOMINGCLASSES: "dashboard/getUpcomingClasses",
}
export default actions;