import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    packagePageLoading: false,
    pricePack: [],
    pricePackChannel: undefined,
    discount: [],
    discountChannel: undefined,
    tax: [],
    taxChannel: undefined,
    registrationFee: [],
    registrationFeeChannel: undefined,
    additionalCharge: [],
    additionalChargeChannel: undefined,
    operationType: undefined,
    packages: [],
    packageChan: undefined,
    packageObj: undefined,
    ageRange: [],
    packageType: [],
    skills: undefined,
    packageBookings: undefined,
    packagePricingPacks: undefined,
    pricingPacksByPackage: undefined,
    upcomingClasses: undefined,
    packageActivites: undefined,
    packageSchedule: undefined,
    classPack: [],
    pricePackData: [],
    pricePackDataLoader: false,
    bookings: [],
    listing: [],
    listingRawData: [],
    listingRowDataLoading: false,
    classrooms: [],
    classlevel: [],
    language: [],
    packageNames: []
}

export const packageSlice = createSlice({
    name: 'package',
    initialState: { ...initialState },
    reducers: {
        saveDiscount: (state, action) => {
            state.isLoading = true
        },
        getAllDiscount: (state, action) => {
            state.isLoading = true
        },
        sagaSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        sagaFailed: (state, action) => {
            state.operationType = undefined;
            state.isLoading = false;
            state.packagePageLoading = false
        },
        resetOperationType: (state, action) => {
            state.operationType = undefined;
            state.isLoading = false;
        },
        deleteDiscount: (state, action) => {
            state.isLoading = true;
        },
        createPackage: (state, action) => {
            state.isLoading = true;
        },
        getAllTax: (state, action) => {
            state.isLoading = true
        },
        saveTax: (state, action) => {
            state.isLoading = true
        },
        deleteTax: (state, action) => {
            state.isLoading = true
        },
        savePricePack: (state, action) => {
            state.isLoading = true
        },
        getAllPricePack: (state, action) => {
            state.isLoading = true
        },
        deletePricingPack: (state, action) => {
            state.isLoading = true;
        },
        getAllRegistrationFee: (state, action) => {
            state.isLoading = true
        },
        saveRegistrationFee: (state, action) => {
            state.isLoading = true
        },
        deleteRegistrationFee: (state, action) => {
            state.isLoading = true;
        },
        getAllAdditionalCharge: (state, action) => {
            state.isLoading = true
        },
        saveAdditionalCharge: (state, action) => {
            state.isLoading = true
        },
        deleteAdditionalCharge: (state, action) => {
            state.isLoading = true;
        },
        fetchPackage: (state, action) => {
            state.isLoading = true;
            state.packagePageLoading = true
        },
        deletePackage: (state, action) => {
            state.isLoading = true
        },
        getPackageById: (state, action) => {
            state.isLoading = true
        },
        resetReducerState: (state, action) => {
            Object.assign(state, initialState);
        },
        getAllPricePackByChannel: (state, action) => {
            state.isLoading = true
        },
        resetState: (state, action) => {
            state.packageObj = undefined;
        },
        getPackageType: (state, action) => {
            state.isLoading = true
        },
        getAgeRange: (state, action) => {
            state.isLoading = true
        },
        getSkills: (state, action) => {
            state.isLoading = true
        },
        fetchPackageBookings: (state, action) => {
            state.isLoading = true
        },
        fetchPricingPacksForAllPackages: (state, action) => {
            state.isLoading = true
        },
        fetchPricingPackByPackage: (state, action) => {
            state.isLoading = true
        },
        fetchUpcomingClasses: (state, action) => {
            state.isLoading = true
        },
        fetchPackageActivites: (state, action) => {
            state.isLoading = true
        },
        fetchPackageSchedule: (state, action) => {
            state.isLoading = true
        },
        getClassPackByPackageId: (state, action) => {
            state.isLoading = true
        },
        editPackageDetails: (state, action) => {
            state.isLoading = true
        },
        getPricePackDataForEdit: (state, action) => {
            state.pricePackDataLoader = true
        },
        getAllBooking: (state, action) => {
            state.isLoading = true
        },
        changeBookingStatus: (state, action) => {
            state.isLoading = true
        },
        getListing: (state, action) => {
            state.isLoading = true
        },
        getListingRawData: (state, action) => {
            state.isLoading = true
            state.listingRowDataLoading = true
        },
        activeInactivePackage: (state, action) => {
            state.isLoading = true
        },
        getClassLevel: (state, action) => {
            state.isLoading = true;
        },
        getLanguage: (state, action) => {
            state.isLoading = true;
        },
        getAllClassrooms: (state, action) => {
            state.isLoading = true
        },
        getAllPackageNames: (state, action) => {
            state.isLoading = true
        },
        updateGuest: (state, action) => {
            state.isLoading = true
        },
    },
})

export const {
    saveDiscount,
    getAllDiscount,
    resetOperationType,
    deleteDiscount,
    saveTax,
    deleteTax,
    getAllTax,
    savePricePack,
    createPackage,
    getAllPricePack,
    deletePricingPack,
    getAllRegistrationFee,
    saveRegistrationFee,
    deleteRegistrationFee,
    getAllAdditionalCharge,
    saveAdditionalCharge,
    deleteAdditionalCharge,
    getPackageById,
    deletePackage,
    fetchPackage,
    resetReducerState,
    getAllPricePackByChannel,
    resetState,
    getPackageType,
    getAgeRange,
    getSkills,
    fetchPackageBookings,
    fetchPricingPacksForAllPackages,
    fetchPricingPackByPackage,
    fetchUpcomingClasses,
    fetchPackageActivites,
    fetchPackageSchedule,
    getClassPackByPackageId,
    editPackageDetails,
    getPricePackDataForEdit,
    getAllBooking,
    changeBookingStatus,
    getListing,
    getListingRawData,
    activeInactivePackage,
    getLanguage,
    getAllClassrooms,
    getClassLevel,
    getAllPackageNames,
    sagaSuccess,
    updateGuest,
} = packageSlice.actions

export default packageSlice.reducer