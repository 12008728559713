import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  operationType: undefined,
  invoiceList: [],
  studentInvoiceList: [],
  transactionList: [],
  studentIdInvoiceList: null,
  taxes: {},
  discounts: {},
  invoice: null,
  packages: [],
  pricingPackByPackageId: [],
  invoicePDF: undefined,
  invoiceLoader: false,
  refetchInvoice: undefined
}

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: { ...initialState },
  reducers: {
    sagaSuccess: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    sagaFailed: (state, action) => {
      state.operationType = undefined;
      state.isLoading = false
    },
    getInvoiceList: (state, action) => {
      state.isLoading = true
    },
    getStudentInvoiceList: (state, action) => {
      state.isLoading = true
    },
    getStudentInvoiceListFromStudentId: (state, action) => {
      state.studentIdInvoiceList = null;
      state.isLoading = true;
    },
    getTransactions: (state, action) => {
      state.isLoading = true
    },
    getAllTaxes: (state, action) => {
      state.isLoading = true
    },
    getAllDiscounts: (state, action) => {
      state.isLoading = true
    },
    getAllPackages: (state, action) => {
      state.isLoading = true
    },
    getPricePackByPackageId: (state, action) => {
      state.isLoading = true
    },
    createInvoice: (state, action) => {
      state.isLoading = true
    },
    editInvoice: (state, action) => {
      state.isLoading = true
    },
    createBooking: (state, action) => {
      state.isLoading = true
    },
    resetOperationType: (state, action) => {
      state.operationType = undefined
    },
    getInvoiceById: (state, action) => {
      state.isLoading = true
      state.invoice = null
      state.invoiceLoader = true
    },
    addRefund: (state, action) => {
      state.isLoading = true
    },
    addPayment: (state, action) => {
      state.isLoading = true
    },
    sendFeeReminder: (state, action) => {
      state.isLoading = true
    },
    invoicePDF: (state, action) => {
      state.isLoading = true
    },
  }
})

export const {
  getInvoiceList,
  getStudentInvoiceList,
  getStudentInvoiceListFromStudentId,
  getTransactions,
  getAllTaxes,
  getAllDiscounts,
  getAllPackages,
  getPricePackByPackageId,
  createInvoice,
  createBooking,
  resetOperationType,
  getInvoiceById,
  addRefund,
  sendFeeReminder,
  invoicePDF,
  addPayment,
  editInvoice,
  sagaSuccess
} = invoiceSlice.actions

export default invoiceSlice.reducer