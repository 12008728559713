import "firebase/compat/storage";
import { call, takeLatest, put, take } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import dbRef from "../../firebase-api/dbRef";
import fbRefs from "../../libs/fbRef";
import Helper from "../../util/helper";

function* getLastUpdateNotification(request) {
  try {
    const { firebase } = request.payload;
    const updateNotificationChan = yield call(dbRef.getEntitiesByChannel, firebase, fbRefs.lastUpdateNotification + "/" + (Helper.isParent(firebase) ? firebase.parent.id : firebase.teacher.id));
    while (true) {
      let lastUpdateNotification = yield take(updateNotificationChan);
      yield put({ type: actions.NOTIFICATION_SAGA_SUCCESS, payload: { lastUpdateNotification, updateNotificationChan, isLoading: false } });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({
      type: actions.NOTIFICATION_SAGA_FAILED
    });
    console.log("failed to fetch parents", err);
  }
}

function* getNotifications(request) {
  try {
    const { firebase } = request.payload;
    const userId = Helper.isParent(firebase) ? firebase?.parent?.id : firebase?.teacher?.id
    let endpoint = `bookingApi/notification/${userId}`
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      let notifications = response.body.data
      yield put({ type: actions.NOTIFICATION_SAGA_SUCCESS, payload: { notifications, isLoading: false } });
    }
    else {
      yield put({ type: actions.NOTIFICATION_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.NOTIFICATION_SAGA_FAILED });
    console.log("failed to delete discount ", err);
  }
}

function* markReadNotification(request) {
  try {
    let { firebase, referenceId, notifications } = request.payload;
    const userId = Helper.isParent(firebase) ? firebase?.parent?.id : firebase?.teacher?.id
    let endpoint = `bookingApi/notification/${userId}`
    let response = yield call(callApi, firebase, "post", endpoint, { referenceId });
    if (response.status == 200) {
      let notification = response.body.data
      notifications = [...notifications.map(noti => {
        if (noti.id === notification.id) return notification;
        else return noti;
      })]
      yield put({ type: actions.NOTIFICATION_SAGA_SUCCESS, payload: { notifications, isLoading: false } });
    }
    else {
      yield put({ type: actions.NOTIFICATION_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.NOTIFICATION_SAGA_FAILED });
    console.log("failed to delete discount ", err);
  }
}

function* markReadAllNotifications(request) {
  try {
    const { firebase } = request.payload;
    const userId = Helper.isParent(firebase) ? firebase?.parent?.id : firebase?.teacher?.id
    let endpoint = `bookingApi/notification/${userId}/all`
    let response = yield call(callApi, firebase, "post", endpoint, {});
    if (response.status == 200) {
      const notifications = response.body.data
      yield put({ type: actions.NOTIFICATION_SAGA_SUCCESS, payload: { notifications, isLoading: false } });
    }
    else {
      yield put({ type: actions.NOTIFICATION_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.NOTIFICATION_SAGA_FAILED });
    console.log("failed to delete discount ", err);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_LAST_UPDATE_NOTIFICATION, getLastUpdateNotification)
  yield takeLatest(actions.GET_NOTIFICATIONS, getNotifications)
  yield takeLatest(actions.MARK_READ_NOTIFICATION, markReadNotification)
  yield takeLatest(actions.MARK_READ_ALL_NOTIFICATIONS, markReadAllNotifications)
}