import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  operationType: undefined,
  calendars: [],
  schedules: [],
  scheduleDetail: null
}

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: { ...initialState },
  reducers: {
    sagaSuccess: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    sagaFailed: (state, action) => {
      state.operationType = undefined;
      state.isLoading = false
    },
    getCalendar: (state, action) => {
      state.isLoading = true;
    },
    getSchedules: (state, action) => {
      state.isLoading = true;
    },
    getScheduleDetail: (state, action) => {
      state.isLoading = true;
    },
  }
})

export const {
  sagaSuccess,
  getCalendar,
  getSchedules,
  getScheduleDetail
} = calendarSlice.actions

export default calendarSlice.reducer