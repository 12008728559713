import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    isLoading: false,
    operationType: undefined,
    staffPackagesAndActivitiesLoading: false,
    staffPackagesAndActivities: [],
    staffSchedules: [],
    packages: [],
    updatedStaff: null
}

export const packageSlice = createSlice({
    name: 'staffs',
    initialState: { ...initialState },
    reducers: {
        getAllStaff: (state, action) => {
            state.isLoading = true;
        },
        getAllPackages: (state, action) => {
            state.isLoading = true;
        },
        getAllStaffPackagesAndActivities: (state, action) => {
            state.staffPackagesAndActivitiesLoading = true;
        },
        updateStaff: (state, action) => {
            state.isLoading = true
        },
        requestSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        requestFailed: (state, action) => {
            state.operationType = undefined;
            state.isLoading = false
        },
        staffResetOperationType: (state, action) => {
            state.operationType = undefined;
            state.isLoading = false;
        },
        deleteStaff: (state, action) => {
            state.isLoading = true
        },
        fetchStaffSchedules: (state, action) => {
            state.isLoading = true
        },
        sendInviteToTeachers: (state, action) => {
            state.isLoading = true
        },
        setActiveInactiveStaff: (state, action) => {
            state.isLoading = true
        },
    }
})
export const { updateStaff, getAllStaff, staffResetOperationType,
    getAllStaffPackagesAndActivities, deleteStaff, fetchStaffSchedules,
    getAllPackages, sendInviteToTeachers, setActiveInactiveStaff, requestSuccess } = packageSlice.actions;
export default packageSlice.reducer
