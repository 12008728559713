import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  operationType: undefined,
  bookings: {},
  revenue: {},
  studentStaff: {},
  packages: [],
  upcomingClasses: []
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: { ...initialState },
  reducers: {
    sagaSuccess: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload);
      }
    },
    sagaFailed: (state, action) => {
      state.operationType = undefined;
      state.isLoading = false
    },
    getBookings: (state, action) => {
      state.isLoading = true;
    },
    getRevenue: (state, action) => {
      state.isLoading = true;
    },
    getStudentStaff: (state, action) => {
      state.isLoading = true;
    },
    getPackages: (state, action) => {
      state.isLoading = true;
    },
    getUpcomingClasses: (state, action) => {
      state.isLoading = true;
    },
  }
})

export const {
  sagaSuccess,
  getBookings,
  getRevenue,
  getStudentStaff,
  getPackages,
  getUpcomingClasses,
} = dashboardSlice.actions

export default dashboardSlice.reducer