import "firebase/compat/storage";
import { call, takeLatest, put, take } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import dayjs from "dayjs";

function* getPackageById(request) {
  try {
    const { firebase, packageId } = request.payload;
    let endpoint = `bookingApi/student/${firebase?.student?.id}/package/${packageId}`;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.PARENT_SAGA_SUCCESS,
        payload: { isLoading: false, packageObj: response.body.data }
      });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PARENT_SAGA_FAILED });
    console.log("failed to fetch package", err);
  }
}

function* fetchPackageActivites(request) {
  try {
    const { firebase, id } = request.payload;
    let endpoint = `bookingApi/student/package/${id}/activities`;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({ type: actions.PARENT_SAGA_SUCCESS, payload: { isLoading: false, packageActivites: response.body.data } });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PARENT_SAGA_FAILED });
    console.log("failed to fetch package activites", err);
  }
}

function* fetchUpcomingClasses(request) {
  try {
    const { firebase, id } = request.payload;
    let endpoint = `bookingApi/student/package/${id}/upcomingClasses`;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({ type: actions.PARENT_SAGA_SUCCESS, payload: { isLoading: false, upcomingClasses: response.body.data } });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PARENT_SAGA_FAILED });
    console.log("failed to fetch package upcoming classes", err);
  }
}

function* getPricePackByPackageId(request) {
  try {
    const { firebase, packageId } = request.payload;
    let endpoint = `bookingApi/student/package/${packageId}/classPack`;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.PARENT_SAGA_SUCCESS,
        payload: { isLoading: false, pricingPackByPackageId: response.body.data }
      });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PARENT_SAGA_FAILED });
    console.log("failed to fetch discounts", err);
  }
}

function* requestApproval(request) {
  try {
    const { payload } = request;
    let { firebase, requestPayload } = payload;
    requestPayload = {
      ...requestPayload,
      status: "ENROLLMENT_PENDING",
      creatorType: "PARENT",
      paymentStatus: "PENDING",
      studentId: firebase?.student?.id,
      createdBy: firebase?.parent?.id,
      createdOn: dayjs().valueOf()
    }
    let endpoint = "bookingApi/booking"
    let response = yield call(callApi, firebase, "post", endpoint, requestPayload);
    if (response?.status == 200) {
      notification("success", formatMsg("packageRequestedSuccessfully"))
      yield put({
        type: actions.PARENT_SAGA_SUCCESS,
        payload: {
          operationType: "REQUESTED_FOR_APPROVAL",
          isLoading: false,
        }
      });
    }
    else {
      // show error message
      yield put({
        type: actions.PARENT_SAGA_FAILED,
        payload: { isLoading: false, }
      });
    }
  } catch (err) {
    console.log("error in request approval  ", err);
    yield put({
      type: actions.PARENT_SAGA_FAILED,
      payload: { isLoading: false, }
    });
  }
}

function* getAllPackages(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingApi/student/${firebase?.student?.id}/packages`
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.PARENT_SAGA_SUCCESS,
        payload: { packagePageLoading: false, isLoading: false, operationType: "FETCH_PACKAGES", packages: response.body.data }
      });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.PARENT_SAGA_FAILED
    });
    console.log("failed to get packages", err);
  }
}

function* getNotificationSettings(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingApi/notificationSettings/${firebase.parent.id}`
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({ type: actions.PARENT_SAGA_SUCCESS, payload: { isLoading: false, operationType: "FETCH_NOTIFICATIONS", notificationSettings: response.body.data } });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.PARENT_SAGA_FAILED
    });
    console.log("failed to get notification settings", err);
  }
}

function* changeNotificationSettings(request) {
  try {
    const { firebase, reqObj } = request.payload;
    let endpoint = `bookingApi/notificationSettings`
    let response = yield call(callApi, firebase, "post", endpoint, reqObj);
    if (response.status == 200) {
      notification("success", formatMsg("success.notificationSettingSaved"))
      yield put({
        type: actions.PARENT_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "FETCH_NOTIFICATIONS", notificationSettings: response.body.data }
      });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.PARENT_SAGA_FAILED
    });
    console.log("failed to submit notification settings", err);
  }
}

function* fetchPricingPacksForAllPackages(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingApi/student/packages/classPack`;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({ type: actions.PARENT_SAGA_SUCCESS, payload: { isLoading: false, packagePricingPacks: response.body.data } });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PARENT_SAGA_FAILED });
    console.log("failed to fetch package pricing packs", err);
  }
}

function* getSellingFast(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingApi/student/packages/sellingFast?studentId=${firebase?.student?.id}`
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      let sellingFast = response.body.data
      yield put({ type: actions.PARENT_SAGA_SUCCESS, payload: { sellingFast, isLoading: false } });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PARENT_SAGA_FAILED });
    console.log("failed to delete discount ", err);
  }
}

function* getMyBookings(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingApi/student/${firebase?.student?.id}/myBookings?startDate=${startDate}&endDate=${endDate}`
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      let myBookings = response.body.data
      yield put({
        type: actions.PARENT_SAGA_SUCCESS,
        payload: { myBookings, isLoading: false, operationType: undefined }
      });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({ type: actions.PARENT_SAGA_FAILED });
    console.log("failed to delete discount ", err);
  }
}

function* updateWishlist(request) {
  try {
    const { firebase, packageId, addOrRemove, packageObj } = request.payload;
    let endpoint = `bookingApi/student/${firebase?.student?.id}/wishlist/${packageId}`;
    let response = yield call(callApi, firebase, addOrRemove ? "post" : "delete", endpoint, {}
    );
    if (response.status == 200) {
      notification("success", formatMsg(addOrRemove ? "packageAddedInWishlist" : "packedRemovedFromWithlist"))
      yield put({
        type: actions.PARENT_SAGA_SUCCESS,
        payload: {
          isLoading: false,
          operationType: "UPDATE_WISHLIST",
          packageObj: packageObj ? { ...packageObj, wishListed: addOrRemove } : null
        }
      });
    }
    else {
      notification("error", formatMsg("failedToUpdateWishlist"))
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    notification("error", formatMsg("PARENT_SAGA_FAILED"))
    yield put({ type: actions.PARENT_SAGA_FAILED });
    console.log("failed to update wishlist ", err);
  }
}

function* leaveReview(request) {
  try {
    const { firebase, reqObj } = request.payload;
    let endpoint = `bookingApi/booking/${reqObj.id}/addReview`
    let response = yield call(callApi, firebase, "post", endpoint, { review: reqObj.review });
    if (response.status == 200) {
      notification("success", response.body.message)
      yield put({
        type: actions.PARENT_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "REVIEW_SUBMITTED" }
      });
    }
    else {
      yield put({ type: actions.PARENT_SAGA_FAILED });
    }
  } catch (err) {
    yield put({
      type: actions.PARENT_SAGA_FAILED
    });
    console.log("failed to submit review", err);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_PARENT_PACKAGE_BY_ID, getPackageById)
  yield takeLatest(actions.FETCH_PARENT_PACKAGE_ACTIVITIES, fetchPackageActivites)
  yield takeLatest(actions.FETCH_PARENT_UPCOMING_CLASSES, fetchUpcomingClasses)
  yield takeLatest(actions.GET_PARENT_PRICE_PACK_BY_PACKAGE_ID, getPricePackByPackageId)
  yield takeLatest(actions.REQUEST_APPROVAL, requestApproval)
  yield takeLatest(actions.PARENT_GET_ALL_PACKAGES, getAllPackages)
  yield takeLatest(actions.PARENT_LEAVE_REVIEW, leaveReview)
  yield takeLatest(actions.PARENT_FETCH_PRICING_PACKS_FOR_ALL_PACKAGES, fetchPricingPacksForAllPackages)
  yield takeLatest(actions.GET_SELLING_FAST, getSellingFast)
  yield takeLatest(actions.UPDATE_WISHLIST, updateWishlist)
  yield takeLatest(actions.GET_MY_BOOKINGS, getMyBookings)
  yield takeLatest(actions.GET_NOTIFICATION_SETTINGS, getNotificationSettings)
  yield takeLatest(actions.CHANGE_NOTIFICATION_SETTINGS, changeNotificationSettings)
}