import "firebase/compat/storage";
import { call, takeLatest, put, take } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import Helper from "../../util/helper";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import genericFunctions from "../../firebase-api/dbRef";
import feRefs from "../../libs/fbRef"
function* createBookingActivity(request) {
    try {
        const { firebase, values } = request.payload;
        let endpoint = `bookingApi/activity`;
        let storagePath = firebase.sbp + "/media/images/";
        if (typeof values.coverPhoto !== 'string') {
            let coverPhotourl = yield call(Helper.getAttachedMediaPath, storagePath, values.coverPhoto, firebase);
            if (coverPhotourl) {
                values.coverPhoto = coverPhotourl[0].path
            }
        }
        if (values.mediaProfiles) {
            let attachmentWithoutUrl = values.mediaProfiles.filter((item) => {
                if (!item.path) {
                    return item
                }
            })
            let attachmentWithUrl = values.mediaProfiles.filter((item) => {
                if (item.path) {
                    return item
                }
            })
            let mediaProfilesUrl = yield call(Helper.getAttachedMediaPath, storagePath, { file: "", fileList: attachmentWithoutUrl }, firebase);
            if (mediaProfilesUrl) {
                values.mediaProfiles = mediaProfilesUrl.concat(attachmentWithUrl);
            }
        }
        values.createdBy = values.name;
        let response: any;
        if (values.id) {
            endpoint = `bookingApi/activity/:${values.id}`;
            response = yield call(callApi, firebase, "post", endpoint, values);
        } else {
            response = yield call(callApi, firebase, "post", endpoint, values);
        }

        if (response.status == 200) {
            yield put({
                type: actions.CREATE_BOOKING_ACTIVITY_SUCCESS
            });
            if (values.id) {
                notification("success", formatMsg("updateActivitySuccess"));

            } else {
                notification("success", formatMsg("createActivitySuccess"));

            }
        }
        else {
            yield put({
                type: actions.REQUEST_FAILED
            });
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({
            type: actions.REQUEST_FAILED
        });
        console.log("failed to create activity", err);

    }
}

function* fetchActivity(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = `bookingApi/activity/all/activites`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response && response.status == 200) {
            yield put({ type: actions.ACTIVITY_REQUEST_SUCCESS, payload: { activityList: response.body.data, isLoading: false } });
        } else {
            yield put({
                type: actions.REQUEST_FAILED
            });

        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({
            type: actions.REQUEST_FAILED
        });
        console.log("failed to fetch activity", err);
    }
}

function* getActivityById(request) {
    try {
        const { firebase, activityId } = request.payload;
        let endpoint = `bookingApi/activity/${activityId}`;
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response && response.status == 200) {
            yield put({ type: actions.ACTIVITY_REQUEST_SUCCESS, payload: { activityObj: response.body.data, isLoading: false, activityLoading: false } });
        } else {
            yield put({
                type: actions.REQUEST_FAILED
            });
        }

    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({
            type: actions.REQUEST_FAILED
        });
        console.log("failed to fetch activity", err);
    }
}

function* deleteActivity(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingApi/activity/" + id;
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            notification("success", formatMsg("deleteActivitySuccess"));
            yield put({ type: actions.ACTIVITY_REQUEST_SUCCESS, payload: { isLoading: false, operationType: "DELETE_ACTIVITY" } });
        }
        else {
            yield put({ type: actions.REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to delete activity ", err);
    }
}

function* getCenterFacilities(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingApi/facility/";
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.ACTIVITY_REQUEST_SUCCESS, payload: { isLoading: false, facilities: response.body.data } });
        }
        else {
            yield put({ type: actions.REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to fetch facilities", err);
    }
}

function* getAllActivityNames(request) {
    try {
        const { firebase } = request.payload;
        let endpoint = "bookingApi/activity/all/name";
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.ACTIVITY_REQUEST_SUCCESS, payload: { isLoading: false, activityNames: response.body.data } });
        }
        else {
            yield put({ type: actions.REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REQUEST_FAILED });
        console.log("failed to fetch activityNames", err);
    }
}




export default function* rootSaga() {
    yield takeLatest(actions.ACTIVITY_CREATE_ACTIVITY, createBookingActivity);
    yield takeLatest(actions.FETCH_ACTIVITY, fetchActivity);
    yield takeLatest(actions.GET_ACTIVITY_BY_ID, getActivityById);
    yield takeLatest(actions.DELETE_ACTIVITY, deleteActivity);
    yield takeLatest(actions.FETCH_FACILITIES, getCenterFacilities);
    yield takeLatest(actions.GET_ALL_ACTIVITY_NAMES, getAllActivityNames);
}

