import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    isLoading: false,
    operationType: undefined,
    parentList: [],
    parentChan: undefined,
    isAlreadyExistForSomeOtherChild: false,
    AlreadyExistMessage: undefined,
    studentPackages: undefined,
    studentSchedules: [],
    studentPackagesLoading: false,
    students: [],
    studentDetail: undefined,
    addSiblingLoader: false
}

export const packageSlice = createSlice({
    name: 'students',
    initialState: { ...initialState },
    reducers: {
        updateStudent: (state, action) => {
            state.isLoading = true
        },
        requestSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        requestFailed: (state, action) => {
            state.operationType = undefined;
            state.isLoading = false;
            state.studentPackagesLoading = false;
        },
        addParent: (state, action) => {
            state.isLoading = true
        },
        fetchParents: (state, action) => {
            state.isLoading = true
        },
        resetState: (state, action) => {
            state.isAlreadyExistForSomeOtherChild = false;
            state.AlreadyExistMessage = undefined;
            state.studentDetail = undefined;
        },
        deleteParent: (state, action) => {
            state.isLoading = true
        },
        fetchStudentPackages: (state, action) => {
            state.isLoading = true
        },
        confirmBooking: (state, action) => {
            state.isLoading = true
        },
        cancelBooking: (state, action) => {
            state.isLoading = true
        },
        fetchStudentSchedules: (state, action) => {
            state.isLoading = true
        },
        getAllStudent: (state, action) => {
            state.isLoading = true;
        },
        getAllStudentPackages: (state, action) => {
            state.studentPackagesLoading = true;
        },
        deleteStudent: (state, action) => {
            state.isLoading = true;
        },
        getStudentDetailById: (state, action) => {
            state.isLoading = true;
        },
        resetOperationType: (state, actions) => {
            state.operationType = undefined
        },
        sendInviteToParent: (state, actions) => {
            state.isLoading = true;
        },
        addSibling: (state, action) => {
            state.addSiblingLoader = true
        },
    }
})
export const { getAllStudent,
    getAllStudentPackages,
    updateStudent,
    addParent,
    fetchParents,
    resetState,
    deleteParent,
    fetchStudentPackages,
    confirmBooking,
    cancelBooking,
    fetchStudentSchedules,
    deleteStudent,
    getStudentDetailById,
    resetOperationType,
    sendInviteToParent, addSibling } = packageSlice.actions;
export default packageSlice.reducer
